'use client';

import { useTranslations } from 'next-intl';
import { useRequest } from 'ahooks';
import { useEffect, useState } from 'react';
import { getProductWebBrowseHistory } from '@/lib/services/zhuye';
import { Goods, NewLoading } from '@/components/commons';
import { useAuthProvider } from '@/lib/context';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel';
import storage from '@/shared/utils/storage';
import { THEME_DARK_MODE } from '@/shared/constants';

/**
 * 最近浏览商品
 * @returns
 */
const RecentlyViewed = ({
  inUserCenterPage = false,
  inCartPage = false,
  inSearchPage = false,
  selectedGoods,
  handleCollection,
}: {
  inUserCenterPage?: boolean;
  inCartPage?: boolean;
  inSearchPage?: boolean;
  selectedGoods?: any;
  handleCollection?: (value: boolean, item: API.WebProductItemVo) => void;
}) => {
  const { isLogin } = useAuthProvider();

  const [isLoginNew, setIsLoginNew] = useState<boolean>(false);

  useEffect(() => {
    setIsLoginNew(!!isLogin);
  }, [isLogin]);

  const t = useTranslations('Home');
  const [darkMode, setDarkMode] = useState<boolean>(false);

  useEffect(() => {
    setDarkMode(storage.get(THEME_DARK_MODE) || false);
  }, []);

  // 每行显示的个数
  const [rowQuantity, setRowQuantity] = useState<number>(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  // 120px是左右边距，固定布局
  useEffect(() => {
    const newRowQuantity = Math.floor(
      (inUserCenterPage ? width - 120 - 120 - 300 - 24 - 24 - 24 : width - 120 - 120) / (180 + 12 + 12),
    );
    setRowQuantity(newRowQuantity);
  }, [inUserCenterPage, width]);

  // 最近浏览商品列表 -- 需要登录
  const {
    data: browseHistoryData,
    loading,
    run,
  } = useRequest(
    () =>
      getProductWebBrowseHistory({
        pageNo: 1,
        pageSize: inUserCenterPage ? rowQuantity * 2 : 30,
      }),
    {
      manual: true,
      cacheKey: 'recent-browse-history',
    },
  );

  useEffect(() => {
    if (isLoginNew && rowQuantity > 0) {
      run();
    }
  }, [isLoginNew, rowQuantity, run]);

  return browseHistoryData?.data?.list && browseHistoryData?.data?.list.length >= rowQuantity ? (
    <section
      className={`box-border w-full py-6 ${
        browseHistoryData?.data?.list && browseHistoryData?.data?.list.length >= rowQuantity ? 'block' : 'hidden'
      }`}
    >
      <div className={inUserCenterPage ? '' : 'container'}>
        <h2 className={`mb-6 text-lg font-semibold leading-6 ${darkMode ? 'text-white' : 'text-black'}`}>
          {t('recentlyViewed')}
        </h2>
        {loading && !(browseHistoryData?.data?.list && browseHistoryData?.data?.list?.length) ? (
          <NewLoading />
        ) : inUserCenterPage || inCartPage || inSearchPage ? (
          <div
            className={`grid justify-between gap-6`}
            style={{ gridTemplateColumns: `repeat(${rowQuantity}, minmax(0, 1fr))` }}
          >
            {(browseHistoryData?.data?.list || []).slice(0, rowQuantity * (inUserCenterPage ? 2 : 1)).map((x) => {
              return (
                <div key={x.productId} className="w-[180px]">
                  <Goods
                    item={{
                      ...x,
                      favorited: x.productId === selectedGoods?.productId ? selectedGoods?.favorited : x.favorited,
                    }}
                    noAnimation={true}
                    handleCollection={handleCollection}
                  />
                </div>
              );
            })}
          </div>
        ) : browseHistoryData?.data?.list?.length === rowQuantity ? (
          <div
            style={{ gridTemplateColumns: `repeat(${rowQuantity}, minmax(0, 1fr))` }}
            className={`grid justify-between gap-6`}
          >
            {(browseHistoryData?.data?.list || []).slice(0, rowQuantity).map((x) => {
              return (
                <div key={x.productId} className="w-[180px]">
                  <Goods
                    item={{
                      ...x,
                      favorited: x.productId === selectedGoods?.productId ? selectedGoods?.favorited : x.favorited,
                    }}
                    handleCollection={handleCollection}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <Carousel
            opts={{
              align: 'start',
            }}
            className="w-full"
          >
            <CarouselContent>
              {(browseHistoryData?.data?.list || []).map((browseHistoryItem, index) => (
                <CarouselItem key={index} className={`basis-1/${rowQuantity} pl-6 first:pl-0`}>
                  <div
                    key={browseHistoryItem.productId}
                    className="flex w-[180px] flex-row items-center justify-center"
                  >
                    <div className="w-[180px]">
                      <Goods
                        item={{
                          ...browseHistoryItem,
                          favorited:
                            browseHistoryItem.productId === selectedGoods?.productId
                              ? selectedGoods?.favorited
                              : browseHistoryItem.favorited,
                        }}
                        handleCollection={handleCollection}
                      />
                    </div>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        )}
      </div>
    </section>
  ) : (
    ''
  );
};

export default RecentlyViewed;
